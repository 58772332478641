@charset "utf-8";

@font-face {
	font-family: 'NotoSansKR Regular';
	src: url('./font/notokr-regular.eot');
	src: url('./font/notokr-regular.eot?#iefix') format('embedded-opentype'),
		url('./font/notokr-regular.woff2') format('woff2'), url('./font/notokr-regular.woff') format('woff'),
		url('./font/notokr-regular.ttf') format('truetype');
		/* url('./font/notokr-regular.svg#notokr-regular') format('svg'); */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NotoSansKR Light';
	src: url('./font/notokr-light.eot');
	src: url('./font/notokr-light.eot?#iefix') format('embedded-opentype'),
		url('./font/notokr-light.woff2') format('woff2'), url('./font/notokr-light.woff') format('woff'),
		url('./font/notokr-light.ttf') format('truetype');
		/* url('./font/notokr-light.svg#notokr-light') format('svg'); */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NotoSansKR Bold';
	src: url('./font/notokr-bold.eot');
	src: url('./font/notokr-bold.eot?#iefix') format('embedded-opentype'),
		url('./font/notokr-bold.woff2') format('woff2'), url('./font/notokr-bold.woff') format('woff'),
		url('./font/notokr-bold.ttf') format('truetype');
		/* url('./font/notokr-bold.svg#notokr-bold') format('svg'); */
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NotoSansKR Black';
	src: url('./font/notokr-black.eot');
	src: url('./font/notokr-black.eot?#iefix') format('embedded-opentype'),
		url('./font/notokr-black.woff2') format('woff2'), url('./font/notokr-black.woff') format('woff'),
		url('./font/notokr-black.ttf') format('truetype');
		/* url('./font/notokr-black.svg#notokr-black') format('svg'); */
	font-weight: normal;
	font-style: normal;
}
