@charset "utf-8";

.notoL {
	font-family: 'NotoSansKR Light';
}
.notoR {
	font-family: 'NotoSansKR Regular';
}
.notoB {
	font-family: 'NotoSansKR Bold';
}
.notoBB {
	font-family: 'NotoSansKR Black';
}
/* font color */
.f_color_w {
	color: #fff;
}
.f_color_bl {
	color: #000;
}
.f_color_g {
	color: #555;
}
.f_color_blue1 {
	/* 블루 */
	color: #219cdc;
}
.f_color_blue {
	/* 블루 */
	color: #00397b;
}
.f_color_orange {
	/* 주황 */
	color: #ff8a00;
}
.f_color_red {
	/* 주황 */
	color: #ff0000;
}
/* 배경색 */
.bg_w {
	background: #fff;
}
.bg_b {
	background: #000;
}
.bg_blue1 {
	/* 블루 */
	background: #219cdc;
}
.bg_blue {
	/* 블루 */
	background: #00397b;
}
.bg_orange {
	/* 주황 */
	background: #ff8a00;
}
.bg_red {
	/* 빨강 */
	background: #ff0000;
}
.bg_green {
	/* 녹색 */
	background: #106731;
}
.bg_skin_color {
	/* 살색 */
	background: #ffeaea;
}
.li_100 {
	width: 100% !important;
}
/* 타이틀 */
.h2_title {
	padding: 22px 0;
	text-align: center;
	letter-spacing: -0.05em;
	font-size: 20px;
}
/* input 디자인 */
.input_style_01 {
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-indent: 8px;
	border: 1px solid #5f7ea2;
}
.input_style_02 {
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-indent: 8px;
	color: #fff;
	border: 1px solid #ff8a00;
}

.input_style_02::placeholder {
	color: #fff;
	font-family: 'NotoSansKR Light';
	opacity: 1;
}
.input_style_02:-ms-input-placeholder {
	color: #fff;
}
.input_style_02::-ms-input-placeholder {
	color: #fff;
}
