@charset "utf-8";

html {
	overflow-y: scroll;
	width: 100%;
	min-width: 100%;
	height: 100%;
	min-height: 100%;
	background: #f9f9f9;
}
body {
	position: relative;
	margin: 0 auto;
	padding: 0;
	max-width: 720px;
	width: 100%;
	min-width: 320px;
	min-height: 100%;
	font-size: 15px;
	font-family: "NotoSansKR Regular", dotum, sans-serif;
	background: #fff;
	word-wrap: break-word;
	background: url("../img/bg.jpg") no-repeat fixed center top;
	background-size: cover;
}
html,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
img {
	margin: 0;
	padding: 0;
	border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ul,
dl,
dt,
dd {
	margin: 0;
	padding: 0;
	list-style: none;
}
legend {
	position: absolute;
	margin: 0;
	padding: 0;
	font-size: 0;
	line-height: 0;
	text-indent: -9999em;
	overflow: hidden;
}
label,
input,
button,
select,
img {
	vertical-align: middle;
}
input,
button {
	margin: 0;
	padding: 0;
	outline: none;
}
input[type="submit"] {
	cursor: pointer;
}
input:focus {
	outline: none;
}
button {
	cursor: pointer;
}

textarea,
select {
}
textarea {
	overflow: auto;
}
optgroup {
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td,
th {
	padding: 0;
}
select {
	margin: 0;
}
p {
	margin: 0;
	padding: 0;
	word-break: break-all;
}
hr {
	display: none;
}
pre {
	overflow-x: scroll;
}
a {
	text-decoration: none;
}
address {
	font-style: normal;
}
::placeholder {
	color: #999;
	font-family: "NotoSansKR Light";
	opacity: 1;
}
:-ms-input-placeholder {
	color: #999;
}
::-ms-input-placeholder {
	color: #999;
}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
article {
	position: relative;
}
/* Universal tags */
* {
	box-sizing: border-box;
}
body {
	background: url("../img/bg.jpg") no-repeat fixed center top;
	background-size: cover;
}
/* 이용자 화면 약관 동의 레이아웃 */
.agreement__contents {
	margin: 0;
	padding-bottom: 70px;
	width: 100%;
}
.head_logo {
	padding: 25px 0 20px 0;
	text-align: center;
	background: #fff;
	border-bottom: 1px solid #d2d2d2;
}
.head_logo .logo {
	height: 26px;
}
.agreement__contents_body {
	position: relative;
	padding: 0 15px;
	width: 100%;

	border-top: 5px solid #ff8a00;
}
.agreement__contents_foot_fixed {
	position: fixed;
	bottom: 0;
	width: 100%;
	max-width: 720px;
}
.agreement__contents_foot_fixed > ul:after {
	display: block;
	visibility: hidden;
	clear: both;
	content: "";
}
.agreement__contents_foot_fixed > ul > li {
	float: left;
	width: 50%;
	height: 50px;
	line-height: 50px;
}
button.btn_n1 {
	width: 100%;
	height: 100%;
	min-height: 100%;
	text-align: center;
	border: none;
}
button.btn_n2 {
	width: 100%;
	height: 100%;
	min-height: 100%;
	text-align: center;
	border: none;
}
/* 약관 체크 디자인 */
.chk_all_box {
	position: relative;
	display: inline-block;
	padding: 15px;
	width: 100%;
	color: #fff;
	background: #00397b;
}

.selec_chk {
	float: right;
}
.chk_text {
	float: left;
}
.fregister_agree {
	margin: 15px 0 0 0;
	position: relative;
	padding: 15px;
	background: #fff;
}
.fregister_agree2 {
	margin: 45px 0 0 0;
	position: relative;
	padding: 15px;
	background: #fff;
}
.fieldset_box {
	display: inline-block;
	padding: 0 0 15px 0;
	width: 100%;
	height: 60px;
	line-height: 26px;
}
.input_container {
	height: 50px;
}
.fieldset_box:after {
	display: block;
	visibility: hidden;
	clear: both;
	content: "";
}
.div_textarea_agree {
	margin-top: 15px;
	padding: 0 0 10px 0;
}
.textarea_agree {
	padding: 8px;
	width: 100%;
	min-height: 100px;
	font-size: 0.9em;
	line-height: 120%;
	color: #56687b;
	border: 1px solid #5f7ea2;
	resize: none;
}
p.text {
	width: calc(100% - 65px);
	line-height: 120%;
}
p.text span {
	display: inline-block;
	margin-left: 7px;
	font-size: 0.85em;
}
/* 체크 디자인 변경 */
/* Hide the browser's default checkbox */
.chk_container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 14px;
	right: 11px;
	height: 26px;
	width: 26px;
	border-radius: 26px;
	background-color: #eee;
	border: 2px solid #00397b;
}

/* On mouse-over, add a grey background color */
.chk_container:hover input ~ .checkmark {
	background-color: #ccc;
	border: 2px solid #00397b;
}

/* When the checkbox is checked, add a blue background */
.chk_container input:checked ~ .checkmark {
	background-color: #ff8a00;
	border: 2px solid #ff8a00;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.chk_container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.chk_container .checkmark:after {
	left: 7px;
	top: 2px;
	width: 8px;
	height: 13px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* 접수 페이지 */
.input_box {
	padding: 10px 0;
}
.input_box .tx1 {
	padding: 0 0 10px 0;
	letter-spacing: -0.05em;
}
.resident_number {
}
.resident_number:after {
	display: block;
	visibility: hidden;
	clear: both;
	content: "";
}
.resident_number > li:nth-child(1) {
	float: left;
	width: 48%;
}
.resident_number > li:nth-child(2) {
	float: left;
	width: 4%;
	text-align: center;
	height: 40px;
	line-height: 38px;
}
.resident_number > li:nth-child(3) {
	float: right;
	width: 48%;
}

.end_text1 {
	padding: 35px 0;
	line-height: 160%;
	text-align: center;
}
.end_text1 span {
	font-size: 1.15em;
}
.end_text2 {
	padding: 25px 20px;
	margin-top: 1px;
	line-height: 160%;
	text-align: center;
	font-size: 0.85em;
}
.n_ft {margin:0 auto;padding:30px 0;width:100%;max-width:720px;font-size:14px;}
.n_ft .tx1 {width:100%;font-size:0.9em;text-align:center;color:#d8eaf3;line-height:150%;}
.n_ft .tx1 > span {padding:0 15px;}
.n_ft .tx2 {padding-top:15px;font-size:0.8em;text-align:center;color:#74bbe2;}
